import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { SwanConfiguration } from './src/components/shared/SwanConfiguration';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
  props,
}: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SwanConfiguration {...props}>{element}</SwanConfiguration>
);
